import React from "react"

import "./loader.scss"

export default props => {
   return (
      <div id="appLoader" className={!props.isLoading ? "disabled" : ""}>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.75 121.31" style={{}}>
            <path d="M187.06,7.09a21.56,21.56,0,0,0-16-7.09h-37L96.47,41.17,58.36,0H21.6A21.6,21.6,0,0,0,0,21.6L.09,99.7a21.6,21.6,0,0,0,21.6,21.6h171s.05-15.67.05-21.61l-.1-78.1A21.56,21.56,0,0,0,187.06,7.09Zm-45.2,83.57L115.38,61.55l26.7-30.93H164.5L137.69,61l26.88,29.7Z" />
         </svg>
      </div>
   )
}
