import IMG1x from "../images/image_equipe.jpg"
import IMG2x from "../images/image_equipe@2x.jpg"

export default {
   title: "L’équipe s’est agrandie",
   content:
      "<p>En 2019, nous avons renforcé <b>notre équipe</b> pour mieux atteindre nos objectifs.</p><p><b>Jérémy</b>, <b>Lionel</b>, <b>Martial</b> et <b>Vincent</b> sont évidement toujours fidèles au poste. <b>Benoit</b> (designer et développeur web), collaborateur freelance depuis plus de 2 ans, a rejoint Mediakod à temps plein.</p><p><b>Logan</b> (frontend développeur) a obtenu un CDi.</p><p><b>Aurélie</b> (assistante administrative) nous a rejoints fin 2019.</p><p>Enfin, <b>Marc</b> (motion designer) a pris ses quartiers dans nos bureaux pour intensifier notre collaboration.</p>",
   contentSecond:
      "<p>N’oublions pas les stagiaires !</p><p>L’année 2019 a aussi était riche en rencontres avec plusieurs étudiants. Félicitations à eux d’avoir supporté l’ambiance de chez nous : Thibaut, Lucas*, Lauren, Iris, Romain*, Natan, Dylan, Mélissa, Marvin, Aurélie, Ruben, Boris, Charlie et Deborah.<br /><i>*mention spéciale 2e stage</i></p>",
   image: IMG1x,
   image2x: IMG2x,
   membres: 8,
   stagiaires: 13,
}
