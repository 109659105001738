import React from "react"
import { Parallax } from "../../../../../custom_modules/react-skrollr"

import ButtonScrollTo, { SmallScrollTo } from "../../../global/ButtonScrollTo"

// Data
import data from "../../../../data/clients.json"

const SectionShared = props => {
   const startingPoint = props.startingPoint

   const mainTransition = {}
   mainTransition[`data-start`] = "opacity: 0; transform: translateY(100%)"
   mainTransition[`data-${startingPoint - 301}`] = "opacity: 0; transform: translateY(100%)"
   mainTransition[`data-${startingPoint - 300}`] = "opacity: 0; transform: translateY(0%)"
   mainTransition[`data-${startingPoint}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 11500}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 12000}`] = "opacity: 1; transform: translateY(-100%)"

   const asideTitleTransition = {}
   asideTitleTransition[`data-${startingPoint + 9000}`] = "opacity: 1;"
   asideTitleTransition[`data-${startingPoint + 9500}`] = "opacity: 0;"

   const thanksTransition = {}
   thanksTransition[`data-${startingPoint + 9500}`] = "transform: translateX(-100%)"
   thanksTransition[`data-${startingPoint + 10000}`] = "transform: translateY(0%)"

   const thanksTitleTransition = {}
   thanksTitleTransition[`data-${startingPoint + 10000}`] = "opacity: 0; transform: translateY(50px)"
   thanksTitleTransition[`data-${startingPoint + 10500}`] = "opacity: 1; transform: translateY(0px)"

   const thanksButtonTransition = {}
   thanksButtonTransition[`data-${startingPoint + 10000}`] = "opacity: 0;"
   thanksButtonTransition[`data-${startingPoint + 10500}`] = "opacity: 1;"

   const asideTransition = {}
   asideTransition[`data-${startingPoint}`] = "transform: translateX(-100%)"
   asideTransition[`data-${startingPoint + 500}`] = "transform: translateY(0%)"

   const firstScrollTransition = {}
   firstScrollTransition[`data-${startingPoint + 1000}`] = "transform: translateY(0px)"
   firstScrollTransition[`data-${startingPoint + 1500}`] = "transform: translateY(200px)"

   const secondScrollTransition = {}
   secondScrollTransition[`data-${startingPoint + 7500}`] = "transform: translateY(200px)"
   secondScrollTransition[`data-${startingPoint + 8500}`] = "transform: translateY(0px)"

   const mainListingTransition = {}
   mainListingTransition[`data-${startingPoint}`] = "transform: translateX(100%)"
   mainListingTransition[`data-${startingPoint + 500}`] = "transform: translateX(0%)"

   const listingTransition = {}
   listingTransition[`data-${startingPoint + 1000}`] = "transform: translateY(0%)"
   listingTransition[`data-${startingPoint + 8500}`] = "transform: translateY(-100%)"

   // console.log(`✅ [Section client] end of scroll : ${startingPoint + 12000}`)

   const scrollCounter = (i, maxCounter) => {
      let CSPoint = startingPoint + 700 + 50 * i

      let counterAnimation = {}

      if (i !== 1) {
         counterAnimation[`data-${CSPoint - 50}`] = "opacity: 0;"
      }
      counterAnimation[`data-${CSPoint}`] = "opacity: 1;"
      counterAnimation[`data-${CSPoint + 50}`] = "opacity: 1;"
      if (i !== maxCounter) {
         counterAnimation[`data-${CSPoint + 75}`] = "opacity: 0;"
      }

      return (
         <Parallax key={`counterClient-${i}`} data={counterAnimation}>
            {i}
         </Parallax>
      )
   }

   const getScrollCounter = maxCounter => {
      let arrayOfNumbers = []

      for (let i = 1; i <= maxCounter; i++) {
         arrayOfNumbers.push(scrollCounter(i, maxCounter))
      }

      return arrayOfNumbers
   }

   const listingCategories = () => {
      let duration = Math.trunc(7500 / data.categories.length)
      let arrayOfCategory = []

      for (let i = 1; i < data.categories.length; i++) {
         let csPoint = startingPoint + 800 + duration * i

         let categoryTransition = {}
         if (i !== 1) {
            categoryTransition[`data-${csPoint - duration}`] = "transform: scale(1)"
         }
         categoryTransition[`data-${csPoint}`] = "transform: scale(1.4)"
         if (i !== data.categories.length - 1) {
            categoryTransition[`data-${csPoint + duration + 100}`] = "transform: scale(1)"
         }

         arrayOfCategory.push(
            <Parallax data={categoryTransition}>
               <h4>{data.categories[i - 1]}</h4>
            </Parallax>
         )
      }

      return arrayOfCategory
   }

   return (
      <Parallax data={mainTransition} background={`white`} className="skrollr--section skrollr--section-clients">
         <section className="skrollr section--clients">
            <Parallax data={asideTransition} className="section--clients__aside">
               <Parallax data={asideTitleTransition}>
                  <h1>
                     {data.beforeTitle} <span className="counter">{getScrollCounter(150)}</span> {data.afterTitle}
                  </h1>
               </Parallax>

               <SmallScrollTo data={firstScrollTransition} scrollToPx={startingPoint + 1500} position="bottom-right" color="white" margin={true} />
               <SmallScrollTo data={secondScrollTransition} scrollToPx={startingPoint + 10500} position="bottom-right" color="white" margin={true} />
            </Parallax>

            <Parallax data={mainListingTransition} className="section--clients__listing">
               <Parallax data={listingTransition}>{listingCategories()}</Parallax>
            </Parallax>
         </section>

         <Parallax data={thanksTransition} className="section--client-thanks">
            <Parallax data={thanksTitleTransition}>
               <h1>Merci !</h1>
               <h3>Nous avons beaucoup de chance de vous avoir.</h3>
            </Parallax>
            <ButtonScrollTo data={thanksButtonTransition} title={`C'est presque fini !`} scrollToPx={startingPoint + 12500} />
         </Parallax>
      </Parallax>
   )
}

export default SectionShared
