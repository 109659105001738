import React, { useState, useRef } from "react"
import { Parallax } from "../../../../../custom_modules/react-skrollr"
import ButtonScrollTo from "../../../global/ButtonScrollTo"

const SectionQuiz = props => {
   const startingPoint = props.startingPoint

   const mainTransition = {}
   mainTransition[`data-start`] = "opacity: 1; transform: translateY(100%)"
   mainTransition[`data-${startingPoint - 1}`] = "opacity: 1; transform: translateY(100%)"
   mainTransition[`data-${startingPoint}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 6000}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 6001}`] = "opacity: 1; transform: translateY(-100%)"

   const buttonArrowTransition = {}
   buttonArrowTransition[`data-start`] = "opacity: 0;"
   buttonArrowTransition[`data-${startingPoint + 2500}`] = "opacity: 0;"
   buttonArrowTransition[`data-${startingPoint + 3000}`] = "opacity: 1;"

   // console.log(`✅ [Section Quiz] end : ${startingPoint + 5000}`)

   // Starting script of quiz
   const modalAnswer = useRef(null)

   const [questionID, setQuestionID] = useState(0)
   const [notTheGood, setNotTheGood] = useState(null)
   const [theGood, setTheGood] = useState(null)
   const [badAnswer, setBadAnswer] = useState(0)
   const [score, setScore] = useState(0)

   const getNextQuestion = () => {
      let maxIndex = questions.length - 1

      if (questionID < maxIndex) {
         setQuestionID(questionID + 1)

         modalAnswer.current.style.transform = `translateX(-100vw)`

         setNotTheGood(null)
         setTheGood(null)

         setTimeout(() => {
            modalAnswer.current.style.transition = `none`
            modalAnswer.current.style.transform = `translateX(100vw)`
            modalAnswer.current.classList.remove("is-active")

            setTimeout(() => {
               modalAnswer.current.setAttribute("style", "")
               modalAnswer.current.style.transition = `.3s ease`
            }, 100)
         }, 305)
      }
   }

   const falseAnswer = clickedButton => {
      if (!questions[questionID].answered) {
         setBadAnswer(badAnswer + 1)

         questions[questionID].answered = true
      }

      displayAnswer()
   }

   let scrollToSectionInMobile = 0

   if (typeof window !== "undefined") {
      scrollToSectionInMobile = window.innerWidth > 790 ? startingPoint + 7000 : window.scrollY + window.innerHeight
   } else {
      scrollToSectionInMobile = startingPoint + 700
   }

   const trueAnswer = () => {
      if (!questions[questionID].answered) {
         // On met à jour le score en déduisant le nombre de question et le nombre de mauvaise réponse
         setScore(questions.length - badAnswer)
         questions[questionID].answered = true
      }

      displayAnswer()
   }

   const displayAnswer = () => {
      // On affiche la modal de réponse et la fléche 'question suivante'
      setScore(questions.length - badAnswer)
      modalAnswer.current.classList.add("is-active")
   }

   return (
      <Parallax data={mainTransition} background={`white`} className="skrollr--section skrollr--section-quiz">
         <section className="skrollr section--quiz">
            <div className="skrollr--inner container">
               <div className="quiz-container">
                  <div className="quiz-container__title">
                     <h2>{questions[questionID].title}</h2>
                     <span className="bolder">À votre avis ? Alors ? Alors ? Alors ?</span>
                  </div>

                  <div className="quiz-container__buttons">
                     <button className="button button--false" onClick={() => (questions[questionID].like ? falseAnswer() : trueAnswer("notTheGood"))}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 130">
                           <g fill="none" fillRule="evenodd" stroke="red" strokeWidth="3">
                              <path d="M60.221 82.257l33.16 32.874a5.51 5.51 0 007.745 0h0l13.815-13.697 1.676 1.661a5.397 5.397 0 010 7.679l-15.49 15.357a5.51 5.51 0 01-7.746 0L60.22 93.257l-33.16 32.874a5.51 5.51 0 01-7.745 0l-15.49-15.357a5.397 5.397 0 010-7.679l1.674-1.66 13.816 13.696a5.511 5.511 0 007.564.172l.181-.172 33.16-32.874zM5.499 28.008l31.485 31.213-5.548 5.5-27.61-27.374a5.397 5.397 0 010-7.678l1.673-1.66zm109.442 0l1.676 1.66a5.397 5.397 0 010 7.68L89.004 64.72l-5.547-5.499 31.484-31.213z" />
                              <path d="M27.061 3.311l33.16 32.874L93.381 3.31a5.51 5.51 0 017.745 0l15.491 15.358a5.397 5.397 0 010 7.678l-33.16 32.874 33.16 32.874a5.397 5.397 0 010 7.679l-15.49 15.357a5.51 5.51 0 01-7.746 0L60.22 82.257l-33.16 32.874a5.51 5.51 0 01-7.745 0L3.826 99.774a5.397 5.397 0 010-7.679l33.158-32.874L3.825 26.347a5.397 5.397 0 010-7.678L19.315 3.31a5.51 5.51 0 017.746 0z" />
                           </g>
                        </svg>

                        <span dangerouslySetInnerHTML={{ __html: notTheGood || "On a pas aimé" }} />
                     </button>

                     <button className="button button--true" onClick={() => (questions[questionID].like ? trueAnswer() : falseAnswer("theGood"))}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153 131">
                           <g fill="none" fillRule="evenodd" stroke="#23BF32" strokeWidth="3">
                              <path d="M147.974 28.19l1.634 1.626a5.46 5.46 0 010 7.745l-89.962 89.556a5.517 5.517 0 01-5.304 1.421 5.484 5.484 0 01-2.39-1.28l-.173-.163v-.003L3.829 79.323a5.465 5.465 0 010-7.747l1.633-1.625 46.317 46.14v.003h0l.172.165a5.484 5.484 0 002.39 1.28 5.517 5.517 0 005.305-1.422h0l88.328-87.928z" />
                              <path d="M134.047 3.325l15.561 15.49a5.46 5.46 0 010 7.746l-89.962 89.556a5.517 5.517 0 01-5.304 1.421 5.484 5.484 0 01-2.39-1.28l-.173-.163v-.003L3.829 68.323a5.465 5.465 0 010-7.747l15.562-15.49a5.517 5.517 0 017.64-.135l.14.133v.003L55.73 73.54l70.536-70.215a5.52 5.52 0 017.78 0z" />
                           </g>
                        </svg>

                        <span dangerouslySetInnerHTML={{ __html: theGood || "On a aimé" }} />
                     </button>
                  </div>
               </div>

               <div className="quiz-container quiz-container--answer" ref={modalAnswer}>
                  <div className="inner-container">
                     <div className="quiz-container__title">
                        <span className={`title-status ${questions[questionID].like ? `title-status--true` : `title-status--false`}`}>{questions[questionID].answer}</span>

                        <div dangerouslySetInnerHTML={{ __html: questions[questionID].content }} />
                     </div>

                     <div className={questions.length - 1 === questionID ? "quiz-container__end" : "quiz-container__buttons"}>
                        {questions.length - 1 === questionID ? (
                           <>
                              <h4>
                                 {finalScoreText[score]} Vous avez {score} bonne{score > 1 ? `s` : ``} réponse{score > 1 ? `s` : ``} !
                              </h4>

                              <button className="button button--next" onClick={() => window.scrollTo({ left: 0, top: scrollToSectionInMobile, behavior: "smooth" })}>
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193 119">
                                    <g fill="none" fillRule="evenodd" stroke="#979797" strokeWidth="3">
                                       <path d="M111 117a4 4 0 002.165-.637l75.61-48.668a4 4 0 000-6.727l-2.543-1.636-73.067 47.031a4 4 0 01-1.91.629L111 107a4 4 0 01-4-4v10a4 4 0 004 4zm-4.001-37.667L107 69.332H6a4 4 0 01-4-4h0v10a4 4 0 004 4l100.999.001z" />
                                       <path d="M111 107a4 4 0 002.165-.637l75.61-48.668a4 4 0 000-6.727L113.165 2.3A4 4 0 00107 5.663v33.669H6a4 4 0 00-4 4v22a4 4 0 004 4h101V103a4 4 0 004 4z" />
                                    </g>
                                 </svg>

                                 <span>Impressionné(e)s ? Il y en a encore…</span>
                              </button>
                           </>
                        ) : (
                           <button className="button button--next" onClick={() => getNextQuestion()}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193 119">
                                 <g fill="none" fillRule="evenodd" stroke="#979797" strokeWidth="3">
                                    <path d="M111 117a4 4 0 002.165-.637l75.61-48.668a4 4 0 000-6.727l-2.543-1.636-73.067 47.031a4 4 0 01-1.91.629L111 107a4 4 0 01-4-4v10a4 4 0 004 4zm-4.001-37.667L107 69.332H6a4 4 0 01-4-4h0v10a4 4 0 004 4l100.999.001z" />
                                    <path d="M111 107a4 4 0 002.165-.637l75.61-48.668a4 4 0 000-6.727L113.165 2.3A4 4 0 00107 5.663v33.669H6a4 4 0 00-4 4v22a4 4 0 004 4h101V103a4 4 0 004 4z" />
                                 </g>
                              </svg>

                              <span>Allez, question suivante !</span>
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <ButtonScrollTo data={buttonArrowTransition} title={`Pas envie de jouer ? Pas de souci, on passe à la suite !`} scrollToPx={props.startingPoint + 7000} black={true} />
      </Parallax>
   )
}

export default SectionQuiz

const finalScoreText = ["Nul de chez nul !", "M'ouais !", "Peu mieux faire !", "Tout juste !", "Bravo !", "Excellent !"]

// const yourNull = ["Raté !", "Encore raté ?!", "Vous nous décevez beaucoup !", "Vous êtes mauvais !", "Vous nous devez 10&euro; !"]

const questions = [
   {
      title: "Internautes et collaborateurs sont de plus en plus sensibles à la responsabilité environnementale des entreprises",
      answer: "Mais bien sûr qu'on aime ça l'environnement !",
      content:
         "<h4>Nous sommes convaincus qu'il est de notre devoir de conseiller et de mettre en œuvre des solutions éco-responsables.</h4><p>Trop peu de concepteurs de sites et applications ont connaissance de l'<b>empreinte carbone</b> que génèrent leurs développements. Depuis l'année dernière, nous sommes en capacité de concevoir des produits ayant une empreinte carbone <b>réduite, sous la barre des 0.5gr de CO2 par page.</b></p><p>C’est pour ces raisons que nous sélectionnons des hébergeurs utilisant des <b>énergies renouvelables</b> et recherchons des solutions techniques adaptées et sur mesure.</p>",
      like: true,
      answered: false,
   },
   {
      title: "Habitués de Google, les internautes utilisent de plus en plus les moteurs de recherches internes des sites web",
      answer: "Mais oui, on sait que les internautes n’aiment pas prendre le temps de chercher !",
      content:
         "<h4>Nous sommes persuadés que votre site Web doit permettre à chacun de trouver rapidement l’information.</h4><p>Bien <b>penser l’ergonomie et l’expérience utilisateur</b> de votre site vous permet de <b>passer moins de temps</b> à dire et redire les mêmes informations à vos prospects. Depuis toujours, nous vous aidons à présenter vos contenus pour permettre à vos visiteurs de trouver efficacement l’information. En 2019, nous avons renforcé notre expertise avec les services d’Algolia pour pouvoir développer des moteurs de recherche ou listings avec filtres présentant des <b>résultats instantanés.</b></p>",
      like: true,
      answered: false,
   },
   {
      title: "Un internaute recevrait en moyenne plus de 25 emails par jour",
      answer: "On n'a pas aimé et on souhaite changer ça !",
      content:
         "<h4>Une campagne de marketing digitale ne peut plus être qu’un simple email !</h4><p>Nous renforçons nos campagnes de mailing par un nouveau canal : <b>l’envoi d’un SMS !</b> En effet, les contacts issus de CRM ou listes de prospection ne comportent pas toujours d’adresses emails. Toucher vos prospect via un SMS peut s’avérer devenir une opportunité qualifiée.</p><p>Pour certains de vos clients, <b>une invitation personnalisée envoyée par SMS</b> peut également offrir un sentiment de proximité agréable et permette un <b>meilleur taux de conversion.</b></p>",
      like: false,
      answered: false,
   },
   {
      title: "Concevoir des objets et animations 3D demande de plus en plus d’investissements",
      answer: "On n'a pas aimé et on trouve des alternatives !",
      content:
         "<h4>Nous sommes convaincus que la 3D peut vous aider à mieux présenter vos produits ou services.</h4><p>Habitués à la conception avec le logiciel sous licence 3DS MAX en rendu Vray, nous nous sommes formés à la conception et la réalisation sous Blender (logiciel Open Source gratuit) afin d’être <b>plus réactifs</b>. Nous profiterons d’une communauté active qui soutient les utilisateurs et développe sans cesse l’outil vis-à-vis de leurs retours.</p><p><b>Votre avantage</b> : nous utilisons à présent un outil 3D en constante évolution et avec un flux de travail fluide. Les bénéfices se ressentent dès à présent, sur la rapidité comme sur la qualité des réalisations.</p>",
      like: false,
      answered: false,
   },
   {
      title: "Il existe encore énormément de sites Web qui ne sont pas accessibles à tous",
      answer: "On n'a pas aimé parce que nous sommes pour l’inclusion !",
      content:
         "<h4>Un site doit être utilisable pour tout le monde, y compris les personnes handicapées.</h4><p>En 2019, nous avons continué notre formation à l’accessibilité Web et pouvons dès lors proposer à nos clients des <b>audits d’accessibilité</b> d’interfaces Web ainsi que la <b>mise en conformité</b> de celles-ci. Nous proposons également aux agences ou développeurs des <b>formations</b> pour y parvenir. Chez Mediakod, nous voulons être sûrs que vos supports numériques soient accessibles pour tout le monde.</p><h4>En bref</h4><p>Nous n’aimons pas rester sur nos acquits.<br/>Nous avons perfectionné nos savoir-faires.<br />…et c’est tout bénéfice pour vous !</p>",
      like: false,
      answered: false,
   },
]
