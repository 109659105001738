import React from "react"
import { Parallax } from "../../../../../custom_modules/react-skrollr"
import ButtonScrollTo from "../../../global/ButtonScrollTo"

const SectionFooter = props => {
   const startingPoint = props.startingPoint

   const mainTransition = {}
   mainTransition[`data-start`] = "opacity: 0; transform: translateY(200vh)"
   mainTransition[`data-${startingPoint - 501}`] = "opacity: 0; transform: translateY(100%)"
   mainTransition[`data-${startingPoint - 500}`] = "opacity: 1; transform: translateY(0%)"

   // console.log(`✅ [Section footer] end of scroll : ${startingPoint}`)

   const openWindow = (URL, event, large) => {
      event.preventDefault()
      if (large) {
         console.log("is large")
         window.open(URL, "_blank", "location=yes,height=570,width=820,scrollbars=yes,status=yes")
      } else {
         window.open(URL, "_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes")
      }
   }

   const getShare = () => {
      let objectShareTool = []
      let key = 0

      for (let shareTool in shareTools) {
         objectShareTool.push(
            <li key={`shareTools-${key}`}>
               <a href={shareTools[shareTool].shareUrl} onClick={event => openWindow(shareTools[shareTool].shareUrl, event)}>
                  <span className="visually-hidden">{shareTools[shareTool].title}</span>
                  <i dangerouslySetInnerHTML={{ __html: shareTools[shareTool].icon }} />
               </a>
            </li>
         )

         key++
      }

      objectShareTool.push(
         <li key={`shareTools-${key}`}>
            <a href="mailto:?subject=Découvre la rétrospective 2019 de Mediakod&body=Regarde la rétrospective de Mediakod :  https://2019.mediakod.com !">
               <span className="visually-hidden">Partager par e-mail</span>
               <i>
                  <svg aria-hidden="true" viewBox="0 0 512 512">
                     <path
                        fill="#ee790f"
                        d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                     />
                  </svg>
               </i>
            </a>
         </li>
      )

      return objectShareTool
   }

   return (
      <Parallax data={mainTransition} background={`orange`} className="skrollr--section skrollr--section-footer">
         <section className="skrollr section--footer container">
            <div className="section--footer__social">
               <h3>Partager notre rétrospective 2019</h3>
               <ul>{getShare()}</ul>
            </div>

            <div className="section--footer__review">
               <h5>
                  Ne serait-ce pas le bon moment pour vous demander de nous faire part de votre avis via{" "}
                  <a href="https://www.facebook.com/pg/Mediakod/reviews/" onClick={event => openWindow("https://www.facebook.com/pg/Mediakod/reviews/", event, true)}>
                     Facebook
                  </a>
                  ,{" "}
                  <a
                     href="https://search.google.com/local/writereview?placeid=ChIJu5bFRZzfwkcR2e4mk72zNLA"
                     onClick={event => openWindow("https://search.google.com/local/writereview?placeid=ChIJu5bFRZzfwkcR2e4mk72zNLA", event, true)}
                  >
                     Google
                  </a>{" "}
                  ou par <a href="mailto:coucou@mediakod.com?subject=Merci Mediakod&body=Entrer ici un mot gentil sur votre expérience avec nous">e-mail</a> ?<br />
                  <br />
                  Bisous.
               </h5>
            </div>

            <div className="section--footer__need">
               <ul>
                  <li>
                     <a href="https://www.linkedin.com/company/mediakod/" rel="noopener noreferrer" target="_blank">
                        Suivez notre entreprise sur Linkedin
                     </a>
                  </li>
                  <li>
                     <a href="https://www.facebook.com/Mediakod" rel="noopener noreferrer" target="_blank">
                        Devenez fan de notre page Facebook
                     </a>
                  </li>
                  <li>
                     <a href="https://www.instagram.com/mediakod/" rel="noopener noreferrer" target="_blank">
                        Suivez la vie de notre agence sur Instagram
                     </a>
                  </li>
                  <li>
                     <a href="mailto:coucou@mediakod.com?subject=Bonjour Mediakod !&body=Bonjour Mediakod, entrez ici votre message, on vous réponds avec plaisir !">
                        Contactez-nous pour discuter de vos projets
                     </a>
                  </li>
                  <li>
                     <a href="mailto:coucou@mediakod.com?subject=Vous les mangez à quelle sauce ?&body=Bonjour Mediakod, vous me manquez et j’ai apparemment besoin de vous payer une frite (pas les burgers, n’abusez-pas quand même). On prend rendez-vous ?">
                        Invitez-nous à manger des frites
                     </a>
                  </li>
               </ul>
            </div>
         </section>

         <ButtonScrollTo title={`Revoir la retrospective`} scrollToPx={0} reverse={true} />
      </Parallax>
   )
}

export default SectionFooter

const currentHref = typeof window !== "undefined" ? window.location.href : "https://mediakod.com"

const shareTools = {
   facebook: {
      shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${currentHref}`,
      title: "Partager sur Facebook",
      icon:
         "<svg aria-hidden='true' viewBox='0 0 320 512'><defs /><path fill='#ee790f' d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z' /></svg>",
   },
   linkedin: {
      shareUrl: `http://www.linkedin.com/shareArticle?mini=true&url=${currentHref}&title=Rétrospective%20Mediakod%2019&summary=Découvrez%20la%20rétrospective%202019%20de%20Mediakod%20!`,
      title: "Partager sur LinkedIn",
      icon:
         "<svg aria-hidden='true' viewBox='0 0 448 512'><path fill='#ee790f' d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 01107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'/></svg>",
   },
   twitter: {
      shareUrl: `https://twitter.com/intent/tweet?text=Découvrez%20la%20rétrospective%202019%20de%20Mediakod%20:%20https://2019.mediakod.com%20!`,
      title: "Partager sur Twitter",
      icon:
         "<svg aria-hidden='true' viewBox='0 0 512 512'><path fill='#ee790f' d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'/></svg>",
   },
}
