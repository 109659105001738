import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Parallax } from "../../../../../custom_modules/react-skrollr"

import { SmallScrollTo } from "../../../global/ButtonScrollTo"

import data from "../../../../data/team"

const SectionTeam = props => {
   const startingPoint = props.startingPoint

   const mainTransition = {}
   mainTransition[`data-start`] = "transform: translateY(100%); opacity: 1;"
   mainTransition[`data-${startingPoint}`] = "transform: translateY(100%); opacity: 1;"
   mainTransition[`data-${startingPoint + 1000}`] = "transform: translateY(0%); opacity: 1;"
   mainTransition[`data-${startingPoint + 6900}`] = "transform: translateY(0%); opacity: 1;"
   mainTransition[`data-${startingPoint + 6901}`] = "transform: translateY(-100%); opacity: 1;"

   const titleTransition = {}
   titleTransition[`data-${startingPoint + 1300}`] = "transform: scale(1); opacity: 1;"
   titleTransition[`data-${startingPoint + 2300}`] = "transform: scale(2.5); opacity: 1;"
   titleTransition[`data-${startingPoint + 3300}`] = "transform: scale(5); opacity: 0;"

   const imgTransition = {}
   imgTransition[`data-start`] = "transform: translateY(100%);"
   imgTransition[`data-${startingPoint + 2300}`] = "transform: translateY(100%);"
   imgTransition[`data-${startingPoint + 3300}`] = "transform: translateY(20%);"
   imgTransition[`data-${startingPoint + 6500}`] = "transform: translateY(-100%);"

   const bignumberFirstTransition = {}
   bignumberFirstTransition[`data-start`] = "transform: translateX(50%); opacity: 0;"
   bignumberFirstTransition[`data-${startingPoint + 4300}`] = "transform: translateX(50%); opacity: 0;"
   bignumberFirstTransition[`data-${startingPoint + 4700}`] = "transform: translateX(0%); opacity: 1;"

   const falseFirstTransition = {}
   falseFirstTransition[`data-${startingPoint + 4500}`] = "width: 0%"
   falseFirstTransition[`data-${startingPoint + 4900}`] = "width: 100%"

   const titleFirstTransition = {}
   titleFirstTransition[`data-${startingPoint + 4600}`] = "transform: translateY(25px); opacity: 0;"
   titleFirstTransition[`data-${startingPoint + 4900}`] = "transform: translateY(0px); opacity: 1;"

   // Second transition of big number
   const bignumberSecondTransition = {}
   bignumberSecondTransition[`data-start`] = "transform: translateX(50%); opacity: 0;"
   bignumberSecondTransition[`data-${startingPoint + 4900}`] = "transform: translateX(-50%); opacity: 0;"
   bignumberSecondTransition[`data-${startingPoint + 5300}`] = "transform: translateX(0%); opacity: 1;"

   const falseSecondTransition = {}
   falseSecondTransition[`data-${startingPoint + 5300}`] = "width: 0%"
   falseSecondTransition[`data-${startingPoint + 5700}`] = "width: 100%"

   const titleSecondTransition = {}
   titleSecondTransition[`data-${startingPoint + 5400}`] = "transform: translateY(25px); opacity: 0;"
   titleSecondTransition[`data-${startingPoint + 5800}`] = "transform: translateY(0px); opacity: 1;"

   // console.log(`✅ [Section Team] Last scroll position : ${startingPoint + 5800}`)

   return (
      <Parallax data={mainTransition} background={`white`} className="skrollr--section">
         <section className="skrollr section--team">
            <SmallScrollTo
               data={{
                  "data-12000": "transform: translateY(0px); opacity: 1;",
                  "data-12400": "transform: translateY(400px); opacity: 0;",
               }}
               scrollToPx={startingPoint + 3700}
               position="bottom-right"
               color="black"
               margin={true}
            />

            <div className="skrollr--inner container">
               <Parallax data={titleTransition}>
                  <h2>{data.title}</h2>
               </Parallax>

               <Parallax data={imgTransition} className="skrollr-to-be-absolute section--team__content">
                  <div className="container">
                     {/* <LazyLoadImage srcSet={`${data.image} 1x, ${data.image2x} 2x`} alt="" /> */}
                     <LazyLoadImage src={data.image2x} alt="La merveilleuse équipe de Mediakod" />

                     <div className="section--team__text" dangerouslySetInnerHTML={{ __html: data.content }} />

                     <Parallax data={bignumberFirstTransition} className="big-number first">
                        <span className="number">{data.membres}</span>
                        <div>
                           <span className="false-title">
                              tyrans
                              <Parallax data={falseFirstTransition} />
                           </span>
                           <Parallax data={titleFirstTransition} className="title">
                              collaborateurs
                           </Parallax>
                        </div>
                     </Parallax>

                     <div className="section--team__text" dangerouslySetInnerHTML={{ __html: data.contentSecond }} />

                     <Parallax data={bignumberSecondTransition} className="big-number">
                        <span className="number">{data.stagiaires}</span>
                        <div>
                           <span className="false-title">
                              victimes
                              <Parallax data={falseSecondTransition} />
                           </span>
                           <Parallax data={titleSecondTransition}>
                              <span className="title">stagiaires</span>
                           </Parallax>
                        </div>
                     </Parallax>
                  </div>
               </Parallax>
            </div>
         </section>
      </Parallax>
   )
}

export default SectionTeam
