import React from "react"
import { Parallax } from "../../../../../custom_modules/react-skrollr"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { SmallScrollTo } from "../../../global/ButtonScrollTo"

import data from "../../../../data/counter.js"

const SectionCounter = props => {
   const sectionCounters = data.counters
   const startingPoint = props.startingPoint

   const mainTransition = {}
   mainTransition[`data-${startingPoint}`] = "transform: translateY(100%); opacity: 1;"
   mainTransition[`data-${startingPoint + 300}`] = "transform: translateY(0%); opacity: 1;"
   mainTransition[`data-${startingPoint + 8050}`] = "transform: translateY(0%); opacity: 1;"
   mainTransition[`data-${startingPoint + 9050}`] = "transform: translateY(-100%); opacity: 1;"

   return (
      <Parallax data={mainTransition} background={`orange`} className="skrollr--section">
         <section className="skrollr skrollr--counter">
            <Parallax
               data={{
                  "data-600": "transform: translateY(50px);opacity: 0;",
                  "data-800": "transform: translateY(0px);opacity: 1;",
               }}
            >
               <div className="skrollr--inner">
                  <div className="container">
                     <div className="skrollr--counter__intro">
                        <h3>{data.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: data.content }} />
                     </div>
                  </div>

                  <Parallax
                     data={{
                        "data-0": "opacity: 0",
                        "data-1000": "opacity: 0",
                        "data-1400": "opacity: 1",
                     }}
                  >
                     <div className="skrollr--counter__sliders">
                        {/* //Itération de chaque tableau,
                            // attention à bien reprendre la dernière valeure pour définir la suite du scroll */}

                        {sectionCounters.map((sectionCounter, index) => {
                           let dataStories = {},
                              start = 1200 + 1100 * index,
                              nextSection = sectionCounters.length === index + 1 ? 10060 : 1200 + 1100 * (index + 1) + 1200

                           dataStories[`data-0`] = "transform[swing]: translateX(100%); opacity: 0;"
                           dataStories[`data-${start}`] = "transform[swing]: translateX(100%); opacity: 0;"
                           dataStories[`data-${start + 1200}`] = "transform[swing]: translateX(0%); opacity: 1;"

                           if (sectionCounters.length === index + 1) {
                              // On affiche dans la console la toute dernière position de l'animation
                              //console.log(`✅ [Section Counter] Last scroll position : ${start + 800}`)
                           } else {
                              //dataStories[`data-${start + 1200}`] = "transform: translateX(0%); opacity: 1;"
                              //dataStories[`data-${start + 1000}`] = "transform: translateX(0%); opacity: 1;"
                              dataStories[`data-${start + 2400}`] = "transform[swing]: translateX(-100%); opacity: 0;"
                              //dataStories[`data-${start + 1600}`] = "transform: translateX(-100%); opacity: 0;"
                           }

                           return (
                              <React.Fragment key={`sectionCounter-${index}`}>
                                 <Parallax data={dataStories} className={`skrollr--counter__slide`}>
                                    <div className="counter-slide container">
                                       <div className="counter-slide__thumb--outer">
                                          <div className="counter-slide__thumb--inner">
                                             <LazyLoadImage src={sectionCounter.img} alt="" />
                                             {/* <img src={sectionCounter.img} alt="" /> */}
                                          </div>
                                       </div>
                                       <div className="counter-slide__data">
                                          <h3>
                                             <span>{sectionCounter.counter}</span>
                                             <span dangerouslySetInnerHTML={{ __html: sectionCounter.title }} />
                                          </h3>
                                       </div>

                                       <SmallScrollTo scrollToPx={nextSection} color={`white`} direction="right" position="bottom-right" />
                                    </div>
                                 </Parallax>
                              </React.Fragment>
                           )
                        })}
                     </div>
                  </Parallax>
               </div>
            </Parallax>
         </section>
      </Parallax>
   )
}

export default SectionCounter
