import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Parallax } from "../../../../../custom_modules/react-skrollr"
import { useStaticQuery, graphql } from "gatsby"

import { SmallScrollTo } from "../../../global/ButtonScrollTo"

const SectionMemories = props => {
   const startingPoint = props.startingPoint

   const mainTransition = {}
   mainTransition[`data-start`] = "opacity: 0; transform: translateY(100%) translateX(1000%)"
   mainTransition[`data-${startingPoint}`] = "opacity: 0; transform: translateY(100%) translateX(0%)"
   mainTransition[`data-${startingPoint + 500}`] = "opacity: 1; transform: translateY(0%) translateX(0%)"
   mainTransition[`data-${startingPoint + 6200}`] = "opacity: 1; transform: translateY(0%) translateX(0%)"
   mainTransition[`data-${startingPoint + 6600}`] = "opacity: 0; transform: translateY(0%) translateX(0%)"
   mainTransition[`data-${startingPoint + 6601}`] = "opacity: 0; transform: translateY(-100%) translateX(0%)"

   const memoriesTransition = {}
   // memoriesTransition[`data-${startingPoint + 499}`] = "transform: translateY(10000%)"
   memoriesTransition[`data-${startingPoint + 900}`] = "transform: translateY(50%)"
   memoriesTransition[`data-${startingPoint + 8500}`] = "transform: translateY(-100%)"

   const scrollTransition = {}
   scrollTransition[`data-${startingPoint + 1200}`] = "transform: translateY(0px); opacity: 1;"
   scrollTransition[`data-${startingPoint + 1800}`] = "transform: translateY(400px); opacity: 0;"

   const secondScrollTransition = {}
   secondScrollTransition[`data-${startingPoint + 4700}`] = "transform: translateY(400px); opacity: 0;"
   secondScrollTransition[`data-${startingPoint + 5500}`] = "transform: translateY(0px); opacity: 1;"

   // console.log(`✅ [Section memories] end of scroll : ${startingPoint + 6200}`)

   const data = useStaticQuery(graphql`
      query {
         allFile(filter: { relativeDirectory: { eq: "memories" } }) {
            edges {
               node {
                  childImageSharp {
                     fluid(maxWidth: 650) {
                        ...GatsbyImageSharpFluid
                     }
                  }
               }
            }
         }
      }
   `)

   return (
      <Parallax data={mainTransition} background={`white`} className="skrollr--section skrollr--section-memories">
         <section className="skrollr section--memories container">
            <Parallax data={null} className="section--memories__article">
               <h2>Nous avons passé des moments mémorables</h2>
               <p>
                  Pour la plupart, vous nous connaissez, nous sommes <span>sérieux</span>, <span>prévenants entre collègues</span>, <span>timides</span>, <span>antipathiques</span>, …
               </p>
               <p>Il nous plait de vous (et nous) rappeler la bonne humeur qui règne en permanence dans notre agence avec ces quelques souvenirs de 2019.</p>
            </Parallax>

            <div className="section--memories__aside">
               <Parallax data={memoriesTransition}>
                  {data.allFile.edges.map((file, index) => (
                     <div key={`memoriesPicture-${index}`}>
                        {/* <img src={file.node.childImageSharp.fluid.src} srcSet={file.node.childImageSharp.fluid.srcSet} alt="" /> */}

                        <LazyLoadImage src={file.node.childImageSharp.fluid.src} srcSet={file.node.childImageSharp.fluid.srcSet} alt="" threshold={3000} />
                     </div>
                  ))}
               </Parallax>
            </div>
         </section>

         <SmallScrollTo data={scrollTransition} scrollToPx={startingPoint + 2000} position="bottom-right" color="black" margin={true} />
         <SmallScrollTo data={secondScrollTransition} scrollToPx={61000} position="bottom-right" color="black" margin={true} />
      </Parallax>
   )
}

export default SectionMemories
