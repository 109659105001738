import React, { useState, useEffect } from "react"
import { ParallaxProvider, Parallax } from "../../../custom_modules/react-skrollr"
import SEO from "../seo"

import DebugNavigation from "../debug/navigation"

import Loader from "../global/loader"

// Import des sections
import SectionLoud from "./sections/01-loud/SectionLoud"
import SectionCounter from "./sections/02-counter/SectionCounter"
import SectionTeam from "./sections/04-team/SectionTeam"
import SectionLike from "./sections/05-welike/SectionLike"
import SectionQuiz from "./sections/05-welike/sectionQuiz"
import SectionTravel from "./sections/06-travel/SectionTravel"
import SectionShared from "./sections/07-shared"
import SectionClients from "./sections/08-clients"
import SectionMemories from "./sections/09-memories"
import SectionNewYear from "./sections/10-newyear"

// Import des styles
import "../../assets/sass/main.scss"
import SectionFooter from "./sections/11-footer"

const sectionSkrollrStartingPoint = {
   sectionLoud: 0,
   sectionCounter: 1000,
   sectionTeam: 9050,
   sectionWeLike: 15200,
   sectionQuiz: 16800,
   sectionTravel: 21800,
   sectionShared: 38100,
   sectionClients: 41400,
   sectionMemories: 53000,
   sectionNewYear: 59200,
   sectionFooter: 65200,
}

const RenderParallaxProvier = ({ children, onRender }) => {
   const [windowWidth, setWindowWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 0)

   const updateWindowWidth = () => {
      setWindowWidth(typeof window !== "undefined" ? window.innerWidth : 0)
   }

   useEffect(() => {
      if (typeof window !== "undefined") {
         // For debug
         // window.onscroll = () => console.log(window.scrollY)

         window.addEventListener("resize", updateWindowWidth)
         return () => window.removeEventListener("resize", updateWindowWidth)
      } else {
         return false
      }
   })

   if (typeof window !== "undefined" && windowWidth >= 790) {
      return (
         <>
            <ParallaxProvider
               init={{
                  smoothScrollingDuration: 500,
                  smoothScrolling: true,
                  forceHeight: false,
                  easing: "ease",
                  render: () => {
                     onRender()
                  },
               }}
               getScrollTop={scrollTop => console.log("scrollTop", scrollTop)}
            >
               {children}

               {/* Progress bar */}
               <Parallax
                  data={{
                     "data-0": "width: 0%;",
                     "data-end": "width: 100%;",
                  }}
                  id="progressBar"
               >
                  <Parallax
                     data={{
                        "data-0": "background-color: rgb(255, 255, 255)",
                        "data-9050": "background-color: rgb(255, 255, 255)",
                        "data-9060": "background-color: rgb(28, 27, 24)",
                        "data-15200": "background-color: rgb(28, 27, 24)",
                        "data-15300": "background-color: rgb(255, 255, 255)",
                        "data-16700": "background-color: rgb(255, 255, 255)",
                        "data-16710": "background-color: rgb(28, 27, 24)",
                        "data-21800": "background-color: rgb(28, 27, 24)",
                        "data-21820": "background-color: rgb(230, 208, 0)",
                        "data-38100": "background-color: rgb(230, 208, 0)",
                        "data-38400": "background-color: rgb(28, 27, 24)",
                        "data-51100": "background-color: rgb(28, 27, 24)",
                        "data-51400": "background-color: rgb(255, 255, 255)",
                        "data-52900": "background-color: rgb(255, 255, 255)",
                        "data-52950": "background-color: rgb(28, 27, 24)",
                        "data-59200": "background-color: rgb(28, 27, 24)",
                        "data-59500": "background-color: rgb(255, 255, 255)",
                     }}
                  ></Parallax>
               </Parallax>

               {process.env.NODE_ENV !== "production" && <DebugNavigation allSections={sectionSkrollrStartingPoint} />}
            </ParallaxProvider>
         </>
      )
   } else {
      return <div>{children}</div>
   }
}

const SkrollrIndex = () => {
   const [loading, setLoading] = useState(true)

   const removeLoading = () => setLoading(false)

   return (
      <>
         <SEO />
         <Loader isLoading={loading} />
         <RenderParallaxProvier onRender={removeLoading}>
            <div id="app">
               <SectionLoud />
               <SectionCounter startingPoint={sectionSkrollrStartingPoint.sectionCounter} />
               <SectionTeam startingPoint={sectionSkrollrStartingPoint.sectionTeam} />
               <SectionLike startingPoint={sectionSkrollrStartingPoint.sectionWeLike} />
               <SectionQuiz startingPoint={sectionSkrollrStartingPoint.sectionQuiz} />
               <SectionTravel startingPoint={sectionSkrollrStartingPoint.sectionTravel} />
               <SectionShared startingPoint={sectionSkrollrStartingPoint.sectionShared} />
               <SectionClients startingPoint={sectionSkrollrStartingPoint.sectionClients} />
               <SectionMemories startingPoint={sectionSkrollrStartingPoint.sectionMemories} />
               <SectionNewYear startingPoint={sectionSkrollrStartingPoint.sectionNewYear} />
               <SectionFooter startingPoint={sectionSkrollrStartingPoint.sectionFooter} />
            </div>
         </RenderParallaxProvier>
      </>
   )
}

export default SkrollrIndex
