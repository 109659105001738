// Import GIF
import GIFIdentiteGraphqiues from "../images/gif/counter-identitegraphiques.gif"
import GIFWebsites from "../images/gif/counter-website.gif"
import GIFVideos from "../images/gif/counter-videos.gif"
import GIFGraphiques from "../images/gif/counter-graphiques.gif"
import GIFMailing from "../images/gif/counter-mailing.gif"
import GIFHosting from "../images/gif/counter-hosting.gif"

export default {
   title: "Nous n’avons pas vu passer 2019 tellement nous étions concentrés sur nos projets !",
   content: "<p>Nous avons à nouveau eu d’excellentes opportunités pour mettre <b>nos compétences au profit de nos clients</b>.</p><p>Découvrez les chiffres marquants de nos activités.</p>",
   counters: [
      {
         img: GIFIdentiteGraphqiues,
         counter: "8",
         title: "identités<br />graphiques",
      },
      {
         img: GIFWebsites,
         counter: "33",
         title: "sites web<br/>développés",
      },
      {
         img: GIFVideos,
         counter: "20",
         title: "projets vidéo<br />conçus",
      },
      {
         img: GIFGraphiques,
         counter: "89",
         title: "projets graphiques<br />réalisés",
      },
      {
         img: GIFMailing,
         counter: "+80",
         title: "campagnes emailing<br />pilotées",
      },

      {
         img: GIFHosting,
         counter: "+160",
         title: "sites web<br/> hébergés",
      },
   ],
}
