// 'use strict';

Object.defineProperty(exports, "__esModule", {
   value: true,
})

var _ParallaxProvider = require("./ParallaxProvider")

Object.defineProperty(exports, "ParallaxProvider", {
   enumerable: true,
   get: function get() {
      return _interopRequireDefault(_ParallaxProvider).default
   },
})

var _Parallax = require("./Parallax")

Object.defineProperty(exports, "Parallax", {
   enumerable: true,
   get: function get() {
      return _interopRequireDefault(_Parallax).default
   },
})

function _interopRequireDefault(obj) {
   return obj && obj.__esModule ? obj : { default: obj }
}
