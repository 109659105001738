import React from "react"
import styled from "styled-components"

const NavDebug = styled.nav`
   position: fixed;
   bottom: 0;
   left: 0;
   background: #fff;
   z-index: 1000000;
   opacity: 0;
   padding: 1rem;

   &:hover {
      opacity: 1;
   }

   ul {
      list-style: none;
      padding: 0;
      margin: 0;
   }

   button {
      display: flex;
      width: 100%;
      text-align: center;
      justify-content: center;
      -webkit-appareance: none;
      appareance: none;
      border: 0;
      background: #ccc;
      padding: 0.5rem;

      &:hover {
         background: #bbb;
      }
   }

   li + li {
      margin-top: 0.5rem;
   }
`

export default props => {
   const goToScroll = position => {
      if (typeof window !== `undefined`) {
         window.scrollTo({
            left: 0,
            top: position,
            behavior: "auto",
         })
      }
   }

   return (
      <NavDebug style={{}}>
         <ul>
            {Object.keys(props.allSections).map((item, key) => {
               return (
                  <li key={key}>
                     <button onClick={() => goToScroll(props.allSections[item] + 400)}>{item}</button>
                  </li>
               )
            })}
         </ul>
      </NavDebug>
   )
}
