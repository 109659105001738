import React from "react"
import { Parallax } from "../../../../../custom_modules/react-skrollr"
import { LazyLoadImage } from "react-lazy-load-image-component"

import ButtonScrollTo from "../../../global/ButtonScrollTo"

import IMGKetchup from "../../../../images/ketchup.jpg"
import IMGWillSmith from "../../../../images/willsmith.jpg"

import SVGChoq from "../../../logos/CHOQ"
import SVGCampus from "../../../logos/CAMPUS"
import SVGIfapme from "../../../logos/IFAPME"
import SVGAct from "../../../logos/ACT"
import SVGTyc from "../../../logos/TYC"

import IMGCondorcet from "../../../../images/logos/logo_condorcet.png"
import IMGProrienta from "../../../../images/logos/logo_prorienta.jpg"

const SectionShared = props => {
   const startingPoint = props.startingPoint

   const mainTransition = {}
   mainTransition[`data-start`] = "opacity: 0; transform: translateY(100%)"
   mainTransition[`data-${startingPoint - 301}`] = "opacity: 0; transform: translateY(100%)"
   mainTransition[`data-${startingPoint - 300}`] = "opacity: 0; transform: translateY(0%)"
   mainTransition[`data-${startingPoint}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 2500}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 3200}`] = "opacity: 1; transform: translateY(-100%)"

   const secondSectionTransition = {}
   secondSectionTransition[`data-${startingPoint + 1000}`] = "transform: translateY(100px);opacity: 0;"
   secondSectionTransition[`data-${startingPoint + 1500}`] = "transform: translateY(0px);opacity: 1;"

   // console.log("end of section share : " + (startingPoint + 3000))

   return (
      <Parallax data={mainTransition} background={`white`} className="skrollr--section skrollr--section-shared">
         <section className="skrollr section--shared">
            <Parallax data={null} className="skrollr--inner container section-description">
               <Parallax data={null}>
                  <LazyLoadImage src={IMGKetchup} alt="" threshold={1000} />
               </Parallax>

               <div className="section-description__text">
                  <h3>Nous sommes restés fidèles à nos valeurs</h3>

                  <p>
                     Chez Mediakod, nous sommes tous <b>passionné(e)s par notre métier</b>.<br />
                     Le <b>partage</b> est une <b>valeur au coeur de notre agence</b>.<br /> En 2019, nous avons continué à apporter notre <b>soutien à des initiatives</b> ou associations locales.
                     Plusieurs membres de l’équipe sont également attachés à partager leurs expériences en donnant <b>des formations, des sessions d’informations</b> ou <b>des cours</b> dans
                     l’enseignement supérieur.
                  </p>
               </div>
            </Parallax>

            <Parallax data={secondSectionTransition} className="skrollr--inner section-logos">
               <div className="container">
                  <ul>
                     <li key={`logoList-${0}`}>
                        <SVGCampus />
                     </li>
                     <li key={`logoList-${1}`}>
                        <SVGChoq />
                     </li>
                     <li key={`logoList-${3}`}>
                        <SVGIfapme />
                     </li>
                     <li key={`logoList-${4}`}>
                        <SVGAct />
                     </li>
                     <li key={`logoList-${5}`}>
                        <SVGTyc />
                     </li>
                     <li key={`logoList-${6}`}>
                        <LazyLoadImage src={IMGProrienta} alt="" threshold={1000} />
                     </li>
                     <li key={`logoList-${7}`}>
                        <LazyLoadImage src={IMGCondorcet} alt="" threshold={1000} />
                     </li>
                  </ul>
                  <LazyLoadImage src={IMGWillSmith} alt="" threshold={1000} />
               </div>
            </Parallax>
         </section>

         <ButtonScrollTo title={`Continuez, on a encore deux petits trucs à vous dire`} scrollToPx={startingPoint + 4000} black={true} />
      </Parallax>
   )
}

export default SectionShared
