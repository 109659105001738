import React from "react"

const ImgBrand = () => (
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.59 115.07">
      <path
         d="M131.59 5a15.18 15.18 0 00-11.26-5h-26L67.86 29 41.05 0H15.19A15.19 15.19 0 000 15.19l.07 55a15.19 15.19 0 0015.19 15.14h120.29v-15.2l-.07-54.95A15.15 15.15 0 00131.59 5zm-31.8 58.78L81.17 43.3 100 21.54h15.77L96.86 42.91l18.91 20.89z"
         fill="#fff"
      ></path>
   </svg>
)

export default ImgBrand
