import React from "react"
import { Parallax } from "../../../../../custom_modules/react-skrollr"
import { SmallScrollTo } from "../../../global/ButtonScrollTo"
import { LazyLoadImage } from "react-lazy-load-image-component"

import IMGShip from "../../../../images/spaceship.jpg"

const SectionTravel = props => {
   const startingPoint = props.startingPoint

   const mainTransition = {}
   mainTransition[`data-start`] = "opacity: 1; transform: translateY(100%)"
   mainTransition[`data-${startingPoint}`] = "opacity: 1; transform: translateY(100%)"
   mainTransition[`data-${startingPoint + 600}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 16500}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 16800}`] = "opacity: 0; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 16801}`] = "opacity: 0; transform: translateY(-100%)"

   const textTransition = {}
   textTransition[`data-${startingPoint}`] = "transform: perspective(2500px) rotateX(35deg) translateY(100%);"
   textTransition[`data-${startingPoint + 13000}`] = "transform: perspective(900px) rotateX(35deg) translateY(-150%);"

   const containerTransition = {}
   containerTransition[`data-${startingPoint + 0}`] = "transform: translateY(100%)"
   containerTransition[`data-${startingPoint + 18000}`] = "transform: translateY(-120%)"

   const spaceshipTransition = {}
   spaceshipTransition[`data-${startingPoint + 1700}`] = "transform: translate(-20vh, -120%)  rotate(20deg) ;"
   spaceshipTransition[`data-${startingPoint + 2100}`] = "transform: translate(25vh,-10%)  rotate(10deg)"
   spaceshipTransition[`data-${startingPoint + 2400}`] = "transform: translate(50vh,10%)  rotate(0deg)"
   spaceshipTransition[`data-${startingPoint + 2700}`] = "transform: translate(75vh,-10%)  rotate(-10deg)"
   spaceshipTransition[`data-${startingPoint + 3000}`] = "transform: translate(120vh,-120%)  rotate(-20deg)"

   const scrollToTransition = {}
   scrollToTransition[`data-${startingPoint + 2700}`] = "transform: translateY(0px); opacity: 1;"
   scrollToTransition[`data-${startingPoint + 4000}`] = "transform: translateY(400px); opacity: 0;"

   // console.log(`✅ [Section Travel] end of scroll : ${startingPoint + 16300}`)

   return (
      <Parallax data={mainTransition} background={`black`} className="skrollr--section skrollr--section-travel">
         <section className="skrollr section--travel">
            <Parallax data={spaceshipTransition} className="spaceship">
               <LazyLoadImage src={IMGShip} alt="" threshold={1000} />
            </Parallax>

            <SmallScrollTo data={scrollToTransition} scrollToPx={startingPoint + 3000} position="bottom-right" color="yellow" margin={true} />

            <div className="skrollr--inner container">
               <section className="star-wars">
                  <Parallax data={textTransition} className="crawl">
                     <div className="title">
                        <h1>
                           En 2019
                           <br />
                           nous avons voyagé
                        </h1>
                     </div>

                     <div className="text">
                        <p>
                           Que cela soit pour des <b>événements</b>, des formations ou des projets, nous n’avons pas hésité à traverser les frontières.
                        </p>

                        {/* <p>
                           On a participé à plus de 12 événements Shake Digital Wallonia, Kikk Festival, Bump Festival, WordCamp Paris, Gatsby Day London, Wapitour CCi, React Beer Lille,
                           Internationale Spieltage SPIEL, Soirée sans-titre, Forum de projet CHOQ, Voeux de la CCI Wapi, Pecha Kucha Night
                        </p> */}

                        {/* <p>
                           Depuis la Belgique pour par exemple le Kikk Festival, en passant par l’Allemagne pour le Internationale Spieltage SPIEL, le Royaume-Uni pour le Gatsby Day ou encore la
                           France pour le WordCamp Paris, c’est (déjà) 4 pays différents que notre équipe a visité pour se former ou s’informer.
                        </p> */}
                        {/*
                        <p>
                           Dans le cadre de nos projets, nous avons aussi été amené à parcourir plusieurs kilomètres en Belgique et France. C’est en fin de compte dans plus de 30 villes différentes
                           que nous avons visité des clients ou collaborateurs.
                        </p> */}
                     </div>
                  </Parallax>
               </section>

               <Parallax data={containerTransition} className="container container--smaller">
                  <Parallax data={null} className={`big-number`}>
                     <span className="top-number">
                        on a participé
                        <br />à plus de
                     </span>
                     <span className="number">12</span>
                     <span className="bottom-number">
                        <span>
                           Shake Digital Wallonia, Kikk Festival, Bump Festival, WordCamp Paris, Gatsby Day London, Wapitour CCi, React Beer Lille, Internationale Spieltage SPIEL, Soirée sans-titre,
                           Forum de projet CHOQ, Voeux de la CCI Wapi, Pecha Kucha Night
                        </span>
                        événements
                     </span>
                  </Parallax>

                  <Parallax data={null} className={`big-number`}>
                     <span className="top-number">
                        on a<br />
                        parlé dans
                     </span>
                     <span className="number">7</span>
                     <span className="bottom-number">pays</span>
                  </Parallax>

                  <Parallax data={null} className={`big-number`}>
                     <span className="top-number">
                        on a goûté
                        <br />
                        les spécialités de
                     </span>
                     <span className="number">30</span>
                     <span className="bottom-number">villes</span>
                  </Parallax>

                  <Parallax data={null} className={`big-number big-number--one-col`}>
                     <span className="top-number">
                        sur la route, nous nous sommes
                        <br />
                        fâchés avec
                     </span>
                     <span className="number">UNE</span>
                     <span className="bottom-number">personne</span>
                  </Parallax>

                  <Parallax data={null} className={`big-number big-number--right`}>
                     <span className="top-number">
                        ...mais ça s'est bien terminé
                        <br />
                        quand même
                     </span>
                  </Parallax>
               </Parallax>
            </div>
         </section>
      </Parallax>
   )
}

export default SectionTravel
