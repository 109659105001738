import React from "react"
import { Parallax } from "../../../../../custom_modules/react-skrollr"
import ButtonScrollTo from "../../../global/ButtonScrollTo"
import { LazyLoadImage } from "react-lazy-load-image-component"

import IMGNousAvonsAime from "../../../../images/nousavonsaime.jpg"

const SectionLike = props => {
   const startingPoint = props.startingPoint

   const mainTransition = {}
   mainTransition[`data-start`] = "opacity: 0; transform: translateY(100%)"
   mainTransition[`data-${startingPoint - 1}`] = "opacity: 0; transform: translateY(100%)"
   mainTransition[`data-${startingPoint}`] = "opacity: 0; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 300}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 1500}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 2000}`] = "opacity: 1; transform: translateY(-100%)"

   const overlineTransition = {}
   overlineTransition[`data-${startingPoint + 500}`] = "width: 0%;"
   overlineTransition[`data-${startingPoint + 800}`] = "width: 100%"

   // console.log(`✅ [Section Like] end : ${startingPoint + 1500}`)

   return (
      <Parallax data={mainTransition} background={`white`} className="skrollr--section skrollr--section-like">
         <LazyLoadImage src={IMGNousAvonsAime} className="bg-img" threshold={1000} />

         <section className="skrollr section--like">
            <div className="skrollr--inner container">
               <div className="section--like__text">
                  <h1>En 2019, nous avons aimé / pas aimé</h1>
                  <p>A vous de jouer ! Répondez correctement aux questions en devinant si nous avons apprécié ou non les affirmations suivantes.</p>
               </div>
            </div>
         </section>

         <ButtonScrollTo data={null} title={`Tentez votre chance`} scrollToPx={startingPoint + 2010} />
      </Parallax>
   )
}

export default SectionLike
