import React from "react"
import { Parallax } from "../../../../../custom_modules/react-skrollr"
import { SmallScrollTo } from "../../../global/ButtonScrollTo"

const SectionNewYear = props => {
   const startingPoint = props.startingPoint

   const mainTransition = {}
   mainTransition[`data-start`] = "opacity: 0; transform: translateY(100%)"
   mainTransition[`data-${startingPoint - 1}`] = "opacity: 0; transform: translateY(100%)"
   mainTransition[`data-${startingPoint}`] = "opacity: 0; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 500}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 6200}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 6000}`] = "opacity: 1; transform: translateY(0%)"
   mainTransition[`data-${startingPoint + 6600}`] = "opacity: 1; transform: translateY(-100%)"

   // console.log(`✅ [Section new year] end of scroll : ${startingPoint + 6000}`)

   const beforeTitleTransition = {}
   beforeTitleTransition[`data-${startingPoint + 700}`] = "opacity: 0; transform: translateY(50px)"
   beforeTitleTransition[`data-${startingPoint + 1000}`] = "opacity: 1; transform: translateY(0px)"
   beforeTitleTransition[`data-${startingPoint + 2700}`] = "opacity: 1; transform: translateY(0px)"
   beforeTitleTransition[`data-${startingPoint + 3000}`] = "opacity: 0; transform: translateY(-50px)"

   const titleTransition = {}
   titleTransition[`data-${startingPoint + 900}`] = "opacity: 0; transform: translateY(50px)"
   titleTransition[`data-${startingPoint + 1200}`] = "opacity: 1; transform: translateY(0px)"
   titleTransition[`data-${startingPoint + 2900}`] = "opacity: 1; transform: translateY(0px)"
   titleTransition[`data-${startingPoint + 3200}`] = "opacity: 0; transform: translateY(-50px)"

   const contentTransition = {}
   contentTransition[`data-${startingPoint + 1100}`] = "opacity: 0; transform: translateY(50px)"
   contentTransition[`data-${startingPoint + 1400}`] = "opacity: 1; transform: translateY(0px)"
   contentTransition[`data-${startingPoint + 3100}`] = "opacity: 1; transform: translateY(0px)"
   contentTransition[`data-${startingPoint + 3400}`] = "opacity: 0; transform: translateY(-50px)"

   console.log(startingPoint + 3400)

   const sectionObjectifTransition = {}
   sectionObjectifTransition[`data-${startingPoint + 3500}`] = "transform: translate(300%, 300%)"
   sectionObjectifTransition[`data-${startingPoint + 3501}`] = "transform: translate(-50%, -50%);"

   const titleObjectifTransition = {}
   titleObjectifTransition[`data-${startingPoint + 3550}`] = "opacity: 0; transform: translateY(50px)"
   titleObjectifTransition[`data-${startingPoint + 3950}`] = "opacity: 1; transform: translateY(0px)"

   const objectif1Transition = {}
   objectif1Transition[`data-${startingPoint + 4200}`] = "opacity: 0; transform: translateY(50px)"
   objectif1Transition[`data-${startingPoint + 4500}`] = "opacity: 1; transform: translateY(0px)"

   const objectif2Transition = {}
   objectif2Transition[`data-${startingPoint + 4700}`] = "opacity: 0; transform: translateY(50px)"
   objectif2Transition[`data-${startingPoint + 5000}`] = "opacity: 1; transform: translateY(0px)"

   const objectif3Transition = {}
   objectif3Transition[`data-${startingPoint + 5200}`] = "opacity: 0; transform: translateY(50px)"
   objectif3Transition[`data-${startingPoint + 5500}`] = "opacity: 1; transform: translateY(0px)"

   const scrollTransition = {}
   scrollTransition[`data-${startingPoint + 1000}`] = "transform: translateY(400px); opacity: 0;"
   scrollTransition[`data-${startingPoint + 1400}`] = "transform: translateY(0px); opacity: 1;"
   scrollTransition[`data-${startingPoint + 5900}`] = "transform: translateY(0px); opacity: 1;"
   scrollTransition[`data-${startingPoint + 5901}`] = "transform: translateY(400px); opacity: 0;"

   const secondScrollTransition = {}
   secondScrollTransition[`data-${startingPoint + 5900}`] = "transform: translateY(400px); opacity: 0;"
   secondScrollTransition[`data-${startingPoint + 5901}`] = "transform: translateY(0px); opacity: 1;"

   return (
      <Parallax data={mainTransition} background={`orange`} className="skrollr--section skrollr--section-new-year">
         <section className="skrollr section--new-year container">
            <Parallax data={beforeTitleTransition}>
               <span className="before-title">Assez parlé du passé</span>
            </Parallax>

            <Parallax data={titleTransition}>
               <h1>Nous vous souhaitons une excellente année 2020 !</h1>
            </Parallax>
            <Parallax data={contentTransition}>
               <p>Que chaque jour de cette nouvelle année vous apporte satisfaction et succès, tant dans votre travail que dans votre vie privée.</p>
               <p>Souriez, profitez des petits bonheurs quotidiens et continuez d’avancer.</p>
            </Parallax>

            <Parallax data={sectionObjectifTransition} className="section-objectifs container">
               <Parallax data={titleObjectifTransition}>
                  <h2>
                     Nos objectifs
                     <br />
                     pour 2020 ?
                  </h2>
               </Parallax>

               <div className="objectifs">
                  <Parallax data={objectif1Transition}>
                     <p>Vous aider encore davantage à être (re)connu et plus efficace dans vos communications.</p>
                  </Parallax>

                  <Parallax data={objectif2Transition}>
                     <p>Vous créer des projets différenciants, utiles et performants.</p>
                  </Parallax>

                  <Parallax data={objectif3Transition}>
                     <p>Vous permettre d'avoir plus de temps à consacrer aux actions qui comptent réellement pour vous.</p>
                  </Parallax>
               </div>
            </Parallax>

            <SmallScrollTo data={scrollTransition} scrollToPx={startingPoint + 6000} position="bottom-right" color="white" margin={true} />
            <SmallScrollTo data={secondScrollTransition} scrollToPx={startingPoint + 8000} position="bottom-right" color="white" margin={true} />
         </section>
      </Parallax>
   )
}

export default SectionNewYear
