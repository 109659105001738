import React from "react"
import { Parallax } from "../../../../../custom_modules/react-skrollr"

// Import des médias
import ImgBrand from "../../../../images/brand"

import ButtonScrollTo from "../../../global/ButtonScrollTo"

const SectionLoud = () => {
   return (
      <Parallax
         data={{
            "data-1000": "transform: translateY(0%)",
            "data-1300": "transform: translateY(-100%)",
         }}
         background={`orange`}
         className="skrollr--section"
      >
         {/* Global element */}
         <Parallax
            data={{
               "data-150": "transform: translateY(0px); opacity: 1;",
               "data-350": "transform: translateY(-25px); opacity: 0;",
            }}
            id="brand"
         >
            <ImgBrand />
         </Parallax>

         <section className="skrollr skrollr--loud">
            <div className="skrollr--inner title-container">
               <Parallax
                  data={{
                     "data-150": "transform: translateY(0px); opacity: 1;",
                     "data-350": "transform: translateY(-25px); opacity: 0;",
                  }}
               >
                  <h1>2019</h1>
                  <p>Rétrospective</p>
               </Parallax>

               <ButtonScrollTo
                  data={{
                     "data-150": "transform: translateX(-50%) translateY(0px); opacity: 1;",
                     "data-350": "transform: translateX(-50%) translateY(-25px); opacity: 0;",
                  }}
                  title={`C'est par là que ça se passe`}
                  scrollToPx={650}
                  speed={"slow"}
               />
            </div>

            <div className="skrollr--inner subtitle-container container">
               <Parallax
                  data={{
                     "data-450": "transform: translateY(25px); opacity: 0;",
                     "data-650": "transform: translateY(0px); opacity: 1;",
                  }}
                  className="grid-container"
               >
                  <h2>Revivez notre année 2019</h2>
                  <p>
                     Comme si vous y étiez, prenez 2min 58sec pour <b>scroller gentiment</b> dans cette page. Des réalisations, des voyages, des grands chiffres et de belles aventures... vous allez
                     être surpris(e) de ce que vous allez découvrir !
                  </p>
               </Parallax>
            </div>

            <ButtonScrollTo
               data={{
                  "data-450": "transform: translateX(-50%) translateY(25px); opacity: 0;",
                  "data-650": "transform: translateX(-50%) translateY(0px); opacity: 1;",
               }}
               title={`Ok. Allons-y !`}
               scrollToPx={2300}
               speed={"slow"}
            />
         </section>
      </Parallax>
   )
}

export default SectionLoud
