import React from "react"
import { Parallax } from "../../../../custom_modules/react-skrollr"

import "./style.scss"

export const smoothScroll = (scrollToPx, h, speed) => {
   if (typeof window !== "undefined") {
      if (window.innerWidth > 790) {
         let yPosition = h || window.scrollY
         let timing = (speed => {
            switch (speed) {
               case "fast":
                  return 5
               case "slow":
                  return 10
               case "verySlow":
                  return 50
               default:
                  return 10
            }
         })(speed)

         // Detect if is Safari
         if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            window.scroll({ left: 0, top: scrollToPx })
         } else {
            if (yPosition < scrollToPx) {
               setTimeout(() => {
                  window.scroll({ left: 0, top: yPosition, behavior: "smooth" })
                  smoothScroll(scrollToPx, yPosition + 10, speed)
               }, timing)
            }
         }
      } else {
         window.scroll(0, window.scrollY + window.innerHeight)
      }
   } else {
      return false
   }
}

export const smoothScrollTop = h => {
   if (typeof window !== "undefined") {
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
   } else {
      return 0
   }
}

const ButtonScrollTo = props => {
   return props.reverse ? (
      <Parallax data={props.data} className="button-scrollToArrow-container">
         <button className={`button-scrollToArrow button-scrollToArrow--reverse ${props.black ? "button-scrollToArrow--black" : ""}`} onClick={() => smoothScrollTop()}>
            <span>{props.title}</span>
            <i>
               <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                     fill={`${props.black ? "#000" : "#FFF"}`}
                     d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
                  />
               </svg>
            </i>
         </button>
      </Parallax>
   ) : (
      <Parallax data={props.data} className="button-scrollToArrow-container">
         <button className={`button-scrollToArrow ${props.black ? "button-scrollToArrow--black" : ""}`} onClick={() => smoothScroll(props.scrollToPx, false, props.speed || false)}>
            <span>{props.title}</span>
            <i>
               <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                     fill={`${props.black ? "#000" : "#FFF"}`}
                     d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
                  />
               </svg>
            </i>
         </button>
      </Parallax>
   )
}

export const SmallScrollTo = props => {
   return (
      <Parallax
         data={props.data || null}
         className={`scroll-to--small ${props.position ? ` scroll-to--${props.position}` : ` `}${props.direction ? ` scroll-to--${props.direction}` : ` `}${
            props.color ? ` scroll-to--${props.color}` : ` `
         }${props.margin ? ` scroll-to--margin` : ``}`}
      >
         <button onClick={() => smoothScroll(props.scrollToPx, false, props.speed || false)}>
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path
                  fill={`${props.color || "#FFF"}`}
                  d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
               />
            </svg>
         </button>
      </Parallax>
   )
}
export default ButtonScrollTo
